import React, { useState, Fragment } from 'react'
import './TradeInForm.scss'
import useDataLayer from '../../hooks/useDataLayer';
import axios from 'axios'

export default (props) => {
    const [formStatus, updateFormStatus] = useState({'Submitted': false, 'Loading': false, 'Success': false, 'Failed': false})
    const { title, text, disclaimer, buttonText } = props.data
    const { leadURL, site_id, pageSource, pageURL} = props
    const pushToDataLayer = useDataLayer()
    const submitForm = async (e) => {
        e.preventDefault()

        updateFormStatus({ 'Submitted': true, 'Loading': true, 'Success': false, 'Failed': false})
        document.getElementById('component__container').classList.toggle('successScale')

        let data = Object.fromEntries(new FormData(e.target).entries());
        let postData = {siteId: site_id, AppType: 'Trade-In Application', pageSource, pageURL, lead: {...data}}
        
        try {
            await axios.post(leadURL, JSON.stringify(postData))
            .then((res) => {
                if (res.status === 200) {
                    pushToDataLayer("form-submission", res)
                    pushToDataLayer("trade-in", res)
                    updateFormStatus({'Submitted': true, 'Failed': false, 'Loading': false, 'Success': true})
                    document.getElementById('loadingIcon').classList.toggle('load-complete')
                    document.getElementById('checkIcon').classList.toggle('checkmark')

                    if(window !== undefined) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }
            })
        } catch (err) {
            updateFormStatus({...formStatus, 'Loading': false, 'Failed': true})
            alert(err)
        }
    }

    function Contactinfo() {
        return (
            <div id="component__container" className="input__container">
                <div className="input__title">
                    <h2>CONTACT INFO</h2>
                </div>

                <label>First Name*</label>
                <input type="text" className="input__field" name="FirstName" required />

                <label>Last Name*</label>
                <input type="text" className="input__field" name="LastName" required />

                <label>Email Address*</label>
                <input type="email" className="input__field" name="Email" required />

                <label>Phone #*</label>
                <input type="text" className="input__field" name="Phone" required />

            </div>
        )
    }

    function VehicleInfo() {
        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>VEHICLE INFO</h2>
                </div>

                <label>Year*</label>
                <input type="text" className="input__field" name="Year" required />

                <label>Make*</label>
                <input type="text" className="input__field" name="Make" required />

                <label>Model*</label>
                <input type="text" className="input__field" name="Model" required />

                <label>Trim*</label>
                <input type="text" className="input__field" name="Trim" required />

                <label>Mileage*</label>
                <input type="text" className="input__field" name="Mileage" required />

                <label>VIN*</label>
                <input type="text" className="input__field" name="VIN" required />
            </div>
        )
    }

    function VehicleCondition() {
        return (
            <div className="input__container">
                <div className="input__title">
                    <h2>VEHICLE CONDITION</h2>
                </div>

                <label>Exterior Condition*</label>
                <div className="input__field radio">
                    <label><input type="radio" name="ExtCondition" value="None" required /> None</label>
                    <label><input type="radio" name="ExtCondition" value="Rough" required /> Rough</label>
                    <label><input type="radio" name="ExtCondition" value="Average" required /> Average</label>
                    <label><input type="radio" name="ExtCondition" value="Clean" required /> Clean</label>
                    <label><input type="radio" name="ExtCondition" value="Extra Clean" required /> Extra Clean</label>
                </div>

                <label>Interior Condition*</label>
                <div className="input__field radio">
                    <label><input type="radio" name="IntCondition" value="None" required /> None</label>
                    <label><input type="radio" name="IntCondition" value="Rough" required /> Rough</label>
                    <label><input type="radio" name="IntCondition" value="Average" required /> Average</label>
                    <label><input type="radio" name="IntCondition" value="Clean" required /> Clean</label>
                    <label><input type="radio" name="IntCondition" value="Extra Clean" required /> Extra Clean</label>
                </div>

                <label>Been in an Accident?*</label>
                <div className="input__field radio">
                    <label><input type="radio" name="Accident" value="Yes" required /> Yes</label>
                    <label><input type="radio" name="Accident" value="No" required /> No</label>
                </div>

                <label>Single Owner*</label>
                <div className="input__field radio">
                    <label><input type="radio" name="SingleOwner" value="Yes" required /> Yes</label>
                    <label><input type="radio" name="SingleOwner" value="No" required /> No</label>
                </div>
            </div>
        )
    }

    return (
        <div className="form__container" onSubmit={submitForm}>

            <div className="form__text">
                <h1>{title}</h1>
                <p>{text}</p>
            </div>

            <div className="form__content">
                {!formStatus.Submitted ?
                    <Fragment>
                        <form>
                        <Contactinfo/>
                        <VehicleInfo/>
                        <VehicleCondition/>
                        <div className="input__container">
                            <label>How would you like us to contact you?</label>
                            <div className="input__field checkbox">
                                <label><input name="ContactByCall" type="checkbox" value="Yes" /> Call</label>
                                <label><input name="ContactByText" type="checkbox" value="Yes" /> Text</label>
                                <label><input name="ContactByEmail" type="checkbox" value="Yes" /> Email</label>
                            </div>
                        </div>
                        <button type="submit">{buttonText}</button>
                    </form>
                    <div className="disclaimer__container">{disclaimer}</div>
                </Fragment>
                :
                <div className="onSubmission">
                    <div id="loadingIcon" className="circle-loader">
                        <div id="checkIcon" className="draw"></div>
                    </div>
                    {formStatus.Success ?
                    <Fragment>
                        <h2>Thank you!</h2>
                        <p>Your application has been successfully submitted.</p>
                    </Fragment>
                    : ''}
                </div>
                }
            </div>

        </div>
    )
}